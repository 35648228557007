import { useState, useEffect, useRef, useCallback, MouseEvent, SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios, { AxiosResponse } from 'axios'
import * as Shaka from 'shaka-player/dist/shaka-player.compiled'
import { Box, Fade, List, ListItemButton, Popover } from '@mui/material'
import { styled } from '@mui/system'
import { v4 as uuidv4 } from 'uuid'

import useResizeObserver from 'use-resize-observer'
import BouncingLoader from 'components/BouncingLoader'
import PositionDuration from 'components/PositionDuration'
import PlayPauseButton from 'components/PlayPauseButton'
import ProgressBar from './DeskProgBar'
import SubtitleOverlay from 'components/SubtitleOverlay'
import {
  setVolumeLevel,
  changePlaybackSpeed,
  signalContentEnded,
  setCurrentlyAt,
  setIsBuffering,
} from 'store/reducers/playback'
import {
  setHeadVideoId,
  setFullScreenVideoElem,
  showAbortModal,
  setIsFirstVideoSelected,
  setHeadVideoIndex,
} from 'store/reducers/componentDisplay'
import AlertMsg from 'services/alertMsg'
import Tick from 'assets/icons/tick.svg'
import type { RootState } from 'store/index'
import screenfull from 'screenfull'
import { IMediaTrack } from 'store/storeTypes'
import { playerConfiguration } from 'constants/videoPlayer'
import { usePreviewContext } from 'contexts/PreviewContext'
import VolumeComponent from './components/VolumeComponent'
import PlaybackSpeed from './components/PlaybackSpeed'
import FullscreenComponent from './components/FullscreenComponent'
import { PlayerControls } from './components/PlayerControls'

const COLOR_BY_INDEX = ['#0497BB', '#FDBA3D', '#EBF5FA']

const SHORTCUTS = [
  'Enter',
  'Space',
  'KeyF',
  'KeyM',
  'ArrowUp',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft',
]

function RemixDeskVids() {
  const {
    VideoRefs,
    selectedVideoId,
    setSelectedVideoId,
    showCPanel,
    currentlyAtRef,
    isShakaInstalledOk,
    shakaInstances,
    maxVideoSizeForVariant,
    subtitleRefs,
    displaySubs,
    pressPlayPause,
    updateClipPosition,
    trackPlaybackStateChange,
    seekTo,
    isFirstPlayed,
  } = usePreviewContext()

  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null)
  const [anchorElemVolume, setAnchorElemVolume] = useState<null | HTMLElement>(null)
  const learnObjItem = useSelector((state: RootState) => state.learningObject.item)
  const mediaTracks: IMediaTrack[] = useSelector(
    (state: RootState) => state.learningObject.mediaTracks,
  )
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const pbSpeeds = useSelector((state: RootState) => state.playback.pbSpeeds)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)

  const isVideoLoading = useSelector((state: RootState) => state.componentDisplay.isVideoLoading)
  const isVidControlPanelOn = useSelector(
    (state: RootState) => state.componentDisplay.isVidControlPanelOn,
  )
  const fullScreenVideoElem = useSelector(
    (state: RootState) => state.componentDisplay.fullScreenVideoElem,
  )
  const blackouts = useSelector((state: RootState) => state.componentDisplay.blackouts)
  const hiddens = useSelector((state: RootState) => state.componentDisplay.hiddens)
  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)
  const justOneTrack = useSelector((state: RootState) => state.componentDisplay.justOneTrack)
  const userId = useSelector((state: RootState) => state.userData.id)

  const dispatch = useDispatch()

  const headVideoContainerRef = useRef<HTMLDivElement>(null)
  const sideVideosContainerRef = useRef<HTMLDivElement>(null)

  const videoBoxRefs = useRef<HTMLDivElement[]>([])
  const volumeMem = useRef<number>(0)
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)

  const [progressAt, setProgressAt] = useState<number>(0)
  const [bufferingState, setBufferingState] = useState({})

  const { width: headVidContWidth } = useResizeObserver<HTMLDivElement>({
    ref: headVideoContainerRef,
  })

  const noContentTracks = mediaTracks?.filter((track) => !track.contentTrack)

  const makeVideoBoxStyles = useCallback(
    (vidInd: number, videoId: string, isContentTrack: boolean) => {
      const hiddensArray = hiddens?.filter((value) => value === false)

      if (videoId === headVideoId) {
        return {
          width: '100%',
          height: '100%',
        }
      } else {
        const hiddensBlockStyle = hiddens?.[vidInd] ? 'none' : 'inline-block'
        return {
          minHeight: noContentTracks?.length < 4 ? '48%' : '49.5%',
          height: hiddensArray?.length === 3 ? 'unset' : '100%',
          display: isContentTrack ? 'none' : hiddensBlockStyle,
        }
      }
    },
    [headVideoId, hiddens, noContentTracks.length],
  )

  const makeVideoStyles = useCallback(
    (videoId: string) => {
      const justTwo = noContentTracks?.length === 2
      return {
        width: videoId !== headVideoId && justTwo ? '100%' : 'unset',
        objectFit: videoId !== headVideoId && justTwo ? 'cover' : 'fill',
        height: videoId !== headVideoId && justTwo ? 'auto' : '100%',
        aspectRatio: 16 / 9,
      }
    },
    [headVideoId, hiddens, noContentTracks.length],
  )

  const handleShakaError = (event: any): void => {
    AlertMsg.show({
      type: 'error',
      message: event?.detail?.code,
      context: 'Shaka Player error → ',
    })
  }

  const moveProgressWithKey = (keyboardEvent: number): void => {
    const toWhere = VideoRefs.current[0]!.currentTime + keyboardEvent
    seekTo(toWhere, isPlaying)
  }

  const openSpPicker = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorElem(event.currentTarget)
  }
  const closeSpPicker = () => setAnchorElem(null)
  const isSpeedPopUp = !!anchorElem
  const popoverId = isSpeedPopUp ? 'playback-speed-selector-popover' : undefined

  const openSpVolumePicker = (
    event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>,
  ): void => {
      setAnchorElemVolume(event.currentTarget)
  }
  const closeSpVolumePicker = () => setAnchorElemVolume(null)
  const isVolumePopUp = !!anchorElemVolume
  const popoverVolumeId = isVolumePopUp ? 'playback-volume-selector-popover' : undefined

  const handleSpeedPick = (event: SyntheticEvent<HTMLDivElement>): void => {
    const pbSpeed = parseFloat((event.target as HTMLDivElement).textContent as string)
    dispatch(changePlaybackSpeed(pbSpeed))

    VideoRefs.current.forEach((video: HTMLVideoElement) => {
      video.playbackRate = pbSpeed
    })
  }

  const handleVolumeSlide = (event: Event | null, newLevel: number | number[]) => {
    const soundLevel = newLevel as number
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    VideoRefs.current[trackIndex].volume = soundLevel / 100
    dispatch(setVolumeLevel(soundLevel))
  }

  const handleVolumeKeyDown = (volumeDiff: number) => {
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    const volume = VideoRefs.current[trackIndex].volume * 100
    const volumeUpdate = volume + volumeDiff
    if (volumeUpdate >= 0 && volumeUpdate <= 100) {
      handleVolumeSlide(null, volumeUpdate)
    } else if (volumeUpdate < 0) {
      handleVolumeSlide(null, 0)
    } else {
      handleVolumeSlide(null, 100)
    }
  }

  const muteUnmute = () => {
    const trackIndex = mediaTracks?.findIndex((track) => track.contentTrack)

    if (VideoRefs.current[trackIndex].volume === 0) {
      handleVolumeSlide(null, volumeMem.current)
      volumeMem.current = 0
    } else {
      volumeMem.current = VideoRefs.current[trackIndex].volume * 100
      handleVolumeSlide(null, 0)
    }
  }

  const checkVideoBuffering = (player: any, i: number) => {
    // check video buffering before first play (loading event)
    const eventManager = new Shaka.util.EventManager()
    eventManager.listen(player, 'buffering', (event: any) => {
      handlePlayerLoaded({ [i]: event?.buffering })
    })
  }

  // check buffering of all video
  useEffect(() => {
    const isBuffered = Object.values(bufferingState).some((state) => state === true)
    dispatch(setIsBuffering(isBuffered))
  }, [bufferingState])

  // handle player buffering value
  const handlePlayerLoaded = (buffering: any) => {
    setBufferingState((prev) => ({ ...prev, ...buffering }))
  }

  // handle check players loaded event
  useEffect(() => {
    if (shakaInstances?.current?.length) {
      shakaInstances?.current?.forEach((player: any, i: number) => checkVideoBuffering(player, i))
    }
  }, [shakaInstances, isPlaying, isFirstPlayed])

  useEffect(() => {
    let shakaRefs: any[] = []
    if (mediaTracks.length && isShakaInstalledOk.current && !shakaInstances.current.length) {
      VideoRefs.current.forEach((video: HTMLVideoElement) => {
        const { width, height } = video.getBoundingClientRect()

        if (
          width &&
          height &&
          video.id !== headVideoId &&
          maxVideoSizeForVariant.current.width === 0 &&
          maxVideoSizeForVariant.current.height === 0
        ) {
          maxVideoSizeForVariant.current.width = width
          maxVideoSizeForVariant.current.height = height
        }
      })
      Promise.all(
        VideoRefs.current.map((video: HTMLVideoElement, i: number) => {
          video.volume = mediaTracks?.[i]?.contentTrack ? volumeLevel / 100 : 0

          shakaInstances.current[i] = new Shaka.Player()
          shakaInstances.current[i].attach(video)
          const player = shakaInstances.current[i]

          if (player) {
            player.configure(playerConfiguration)
            player.addEventListener('error', (error: any) => handleShakaError(error))

            // check video buffering before first play (loading event)
            checkVideoBuffering(player, i)

            return player.load(mediaTracks[i].url)
          }
        }),
      )
        .then(() => {
          window.shakaPlayers = shakaInstances.current
          shakaRefs = shakaInstances.current

          const sideHeadVideoBox = videoBoxRefs.current.find((box: HTMLDivElement) => {
            return box.id.slice(9) === headVideoId
          })
          if (sideHeadVideoBox && sideVideosContainerRef.current && headVideoContainerRef.current) {
            const headVideoBoxToMove = sideVideosContainerRef.current.removeChild(sideHeadVideoBox)
            headVideoContainerRef.current.appendChild(headVideoBoxToMove)
          }

          handleGetCurrentPosition()
        })
        .catch((error) => {
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
          console.error('shaka load error: ', error)
        })
    }

    return () => {
      if (shakaRefs?.length)
        shakaRefs.forEach((shaka) => shaka?.removeEventListener('error', handleShakaError))
    }
  }, [mediaTracks, learnObjItem?.type])

  const toggleFullScreen = () => {
    if (screenfull.isEnabled && headVideoContainerRef.current) {
      if (screenfull.element === undefined) {
        screenfull
          .request(headVideoContainerRef.current)
          .then(() => dispatch(setFullScreenVideoElem(headVideoId)))
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't enter fullscreen mode!",
            })
          })
      } else {
        screenfull
          .exit()
          .then(() => dispatch(setFullScreenVideoElem('')))
          .catch(() => {
            AlertMsg.show({
              type: 'warning',
              message: "Can't exit fullscreen mode!",
            })
          })
      }
    }
  }

  useEffect(() => {
    if (selectedVideoId !== '') {
      setTimeout(() => {
        const selectedHeadVideoBox = sideVideosContainerRef.current?.children?.namedItem(
          `VideoBox_${selectedVideoId}`,
        )

        if (
          selectedHeadVideoBox &&
          sideVideosContainerRef.current &&
          headVideoContainerRef.current?.firstElementChild
        ) {
          dispatch(setHeadVideoId(selectedVideoId))
          const selectedVideoIndex = mediaTracks?.findIndex(
            (track: any) => track?.id === selectedVideoId,
          )
          dispatch(setHeadVideoIndex(selectedVideoIndex))
          const newHeadVideoBox = sideVideosContainerRef.current.replaceChild(
            headVideoContainerRef.current.firstElementChild,
            selectedHeadVideoBox,
          )
          headVideoContainerRef.current.appendChild(newHeadVideoBox)

          if (mediaTracks.length > 2) {
            shakaInstances.current.forEach((player: any, i: number) => {
              if (VideoRefs.current[i].id === selectedVideoId && player) {
                const abrConf = player ?? player?.getConfiguration()?.abr
                if (
                  abrConf?.restricions?.maxWidth !== Infinity &&
                  abrConf?.restricions?.maxHeight !== Infinity
                ) {
                  player.configure(playerConfiguration)
                }
              } else {
                const { width, height } = maxVideoSizeForVariant.current
                const abrConf = player ?? player?.getConfiguration()?.abr
                if (
                  player &&
                  abrConf?.restricions?.maxWidth !== width &&
                  abrConf?.restricions?.maxHeight !== height
                ) {
                  player.configure(playerConfiguration)
                }
              }
            })
          }
        }
        setSelectedVideoId('')
      }, 300)
    }
  }, [selectedVideoId, mediaTracks])

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      const { code } = event
      if (
        SHORTCUTS.includes(code) &&
        event.target.tagName !== 'TEXTAREA' &&
        event.target.tagName !== 'INPUT'
      ) {
        event.preventDefault()
        if (!VideoRefs?.current[0]?.paused) showCPanel(true, event)

        switch (code) {
          case 'Enter':
          case 'Space':
            pressPlayPause(VideoRefs?.current[0]?.paused)
            break
          case 'KeyF':
            toggleFullScreen()
            break
          case 'KeyM':
            muteUnmute()
            break
          case 'ArrowUp':
            handleVolumeKeyDown(10)
            break
          case 'ArrowDown':
            handleVolumeKeyDown(-10)
            break
          case 'ArrowRight':
            moveProgressWithKey(10)
            break
          case 'ArrowLeft':
            moveProgressWithKey(-10)
            break
          default:
            break
        }
      }
    }
    if (mediaTracks.length) {
      document.addEventListener('keydown', keyDownHandler)
    }
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [mediaTracks])

  useEffect(() => {
    const forceFsButtonChange = () => {
      if (!screenfull.isFullscreen && fullScreenVideoElem) {
        dispatch(setFullScreenVideoElem(''))
      }
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', forceFsButtonChange)
    }
    return () => {
      if (typeof screenfull.off === 'function') {
        screenfull.off('change', forceFsButtonChange)
      }
    }
  }, [fullScreenVideoElem])

  useEffect(() => {
    displaySubs(subtitleLang)
  }, [subtitleLang])

  const handleGetCurrentPosition = async () => {
    const url = window.__RUNTIME_CONFIG__.PROGRESS_QUERY.replace('{customerId}', userId)
      .replace('{contentId}', learnObjItem?.id)
      .replace('progress', 'position')

    axios
      .get(url)
      .then((response: AxiosResponse) => {
        if (response) {
          const currentPosition = response?.data?.position || 0
          const isContentFinish =
            currentPosition * 0.99 >= Math.floor(learnObjItem?.duration / 1000)

          const newPosition = isContentFinish ? 0 : currentPosition

          setProgressAt(newPosition)
          seekTo(newPosition)
          currentlyAtRef.current = newPosition
          dispatch(setCurrentlyAt(newPosition))

          const contentTrackInd = mediaTracks?.findIndex((track: any) => track?.contentTrack)
          VideoRefs.current[contentTrackInd]!.currentTime = newPosition
        }
      })
      .catch((error: Error) => {
        console.log('error:', error)
      })
  }

  const getPlayerPlaceholderPoster = (video: IMediaTrack) => {
    if (video?.poster && !video?.poster?.includes('undefined')) return video.poster
    else if (video?.landscapeImage && !video?.landscapeImage?.includes('undefined'))
      return `${video?.landscapeImage}?preventCache=${uuidv4()}`
    else if (learnObjItem?.images?.landscapeUrl) return learnObjItem?.images?.landscapeUrl
    else return null
  }

  const handleCheckEndEvent = useCallback(
    async (time: number) => {
      const contentTrackIndex = mediaTracks?.findIndex((track) => track.contentTrack)
      if (time >= learnObjItem?.duration) {
        pressPlayPause(false)
        trackPlaybackStateChange(contentTrackIndex, 'ended', false)
        dispatch(signalContentEnded(true))

        seekTo(0)
      } else {
        dispatch(signalContentEnded(false))
      }
    },
    [mediaTracks, learnObjItem?.duration],
  )

  const handleVideoControls = (video: any, vidInd: number) => {
    return video.id === headVideoId && fullScreenVideoElem !== '' ? (
      <Fade in={isVidControlPanelOn} timeout={500}>
        <VideoControls>
          <ControlsTop>
            <TimeBox>
              <PositionDuration currentlyAtRef={currentlyAtRef} />
            </TimeBox>
          </ControlsTop>

          <ControlsMiddle>
            {isVideoLoading[0] ? <BouncingLoader position="absolute" top={0} zIndex={101} /> : null}
          </ControlsMiddle>

          <ControlsBottom>
            <ProgressBox>
              <ProgressBar
                showCPanel={showCPanel}
                seekTo={seekTo}
                progressAt={progressAt}
                setProgressAt={setProgressAt}
              />
            </ProgressBox>

            <BottomButtons>
              <LeftBB>
                <PlayPauseBox>
                  <PlayPauseButton pressPlayPause={pressPlayPause} videoId={video.id} />
                </PlayPauseBox>

                <VolumeComponent
                  isFullScreen={fullScreenVideoElem !== ''}
                  muteUnmute={muteUnmute}
                  handleVolumeSlide={handleVolumeSlide}
                />
              </LeftBB>

              <RightBB>
                {fullScreenVideoElem === '' && (
                  <>
                    <PlaybackSpeed openSpPicker={openSpPicker} popoverId={popoverId} />
                    <PopOverMenu
                      id={popoverId}
                      open={isSpeedPopUp}
                      anchorEl={anchorElem}
                      onClose={closeSpPicker}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <PlaybackSpeedList id="playback-speed-list">
                        {pbSpeeds.map((pbSpeed: number, i: number) => (
                          <PlaybackSpeedListButton
                            key={'pbSpeed_' + i}
                            onPointerDown={handleSpeedPick}
                          >
                            {`${pbSpeed}x`}
                          </PlaybackSpeedListButton>
                        ))}
                      </PlaybackSpeedList>
                    </PopOverMenu>
                  </>
                )}

                <FullscreenComponent toggleFullScreen={toggleFullScreen} />
              </RightBB>
            </BottomButtons>
          </ControlsBottom>
        </VideoControls>
      </Fade>
    ) : (
      <Fade
        in={isVidControlPanelOn}
        timeout={500}
        onClick={() => video.id === headVideoId && pressPlayPause(!isPlaying, vidInd)}
      >
        <VideoControls>
          <ControlsMiddle>
            {isVideoLoading[0] ? <BouncingLoader position="absolute" top={0} zIndex={101} /> : null}
          </ControlsMiddle>
        </VideoControls>
      </Fade>
    )
  }

  return (
    <>
      <DesktopVideosFrame
        sx={{
          justifyContent: justOneTrack ? 'center' : 'space-between',
          height: (headVidContWidth! * 9) / 16,
          gap: '18px',
        }}
      >
        <HeadVideoContainer
          ref={headVideoContainerRef}
          sx={{ width: justOneTrack ? '80%' : '66.5%' }}
        ></HeadVideoContainer>

        <SideVideosContainer
          ref={sideVideosContainerRef}
          sx={{
            width: mediaTracks?.length < 5 ? '32%' : '34%',
            display: justOneTrack ? 'none' : 'flex',
            gap: '5px',
          }}
        >
          {mediaTracks?.map((video: any, vidInd: number) => {
            const borderColor = !COLOR_BY_INDEX[vidInd] ? COLOR_BY_INDEX[2] : COLOR_BY_INDEX[vidInd]
            return (
              <VideoBox
                key={`VideoBox_${video.id}`}
                id={`VideoBox_${video.id}`}
                ref={(element: HTMLDivElement) => {
                  videoBoxRefs.current[vidInd] = element
                }}
                onPointerMove={(event: any) => {
                  if (event?.nativeEvent?.pointerType === 'mouse' && !isUserSeeking && isPlaying) {
                    showCPanel(true)
                  }
                }}
                onTouchStart={() => {
                  if (!isUserSeeking && isPlaying) showCPanel(true)
                }}
                sx={makeVideoBoxStyles(vidInd, video.id, video?.contentTrack)}
                onClick={() => {
                  if (video.id !== headVideoId) {
                    setSelectedVideoId(video.id)
                    setHeadVideoIndex(vidInd)
                    dispatch(setIsFirstVideoSelected(true))
                  }
                }}
              >
                <Video
                  key={video.id}
                  id={video.id}
                  ref={(element: HTMLVideoElement) => {
                    VideoRefs.current[vidInd] = element
                  }}
                  crossOrigin="anonymous"
                  preload="metadata"
                  poster={getPlayerPlaceholderPoster(video)}
                  playsInline
                  muted={!video.contentTrack}
                  onTimeUpdate={() => {
                    trackPlaybackStateChange(vidInd, 'timeUpdate', false)
                    updateClipPosition()
                  }}
                  onWaiting={() => trackPlaybackStateChange(vidInd, 'waiting', true)}
                  onCanPlayThrough={() => trackPlaybackStateChange(vidInd, 'canPlayThrough', false)}
                  onSeeking={() => trackPlaybackStateChange(vidInd, 'seeking', true)}
                  onSeeked={() => trackPlaybackStateChange(vidInd, 'seeked', false)}
                  onPlay={() => trackPlaybackStateChange(vidInd, 'play', false)}
                  onPlaying={() => trackPlaybackStateChange(vidInd, 'playing', false)}
                  onPause={() => trackPlaybackStateChange(vidInd, 'pause', false)}
                  onError={() => trackPlaybackStateChange(vidInd, 'error', false)}
                  onAbort={() => trackPlaybackStateChange(vidInd, 'aborted', false)}
                  onEnded={() => handleCheckEndEvent(currentlyAtRef.current * 1000)}
                  onLoadedData={() => {
                    VideoRefs.current.map((_: any, i: number) => {
                      const player = shakaInstances.current[i]

                      if (player) checkVideoBuffering(player, i)
                    })
                  }}
                  sx={makeVideoStyles(video.id)}
                  style={{
                    borderRadius: '10px',
                    borderLeft: `10px solid ${borderColor}`,
                  }}
                >
                  {vidInd === 0 &&
                    !!video.subtitles?.length &&
                    video.subtitles
                      .slice(1)
                      .map((subtitle: any) => (
                        <track
                          key={`${video.id}-textTrack-${subtitle.locale}`}
                          id={`${subtitle.locale}`}
                          kind="subtitles"
                          src={subtitle.url}
                          srcLang={subtitle.locale.slice(0, 2)}
                          label={subtitle.label}
                        />
                      ))}
                </Video>

                <SubtitleOverlay video={video} vidInd={vidInd} subtitleRefs={subtitleRefs} />

                {handleVideoControls(video, vidInd)}

                {(!isVideoLoading[vidInd] || (isVideoLoading[vidInd] && blackouts[vidInd])) && (
                  <VideoSelectorOverlay
                    onPointerDown={() => {
                      if (video.id !== headVideoId) {
                        setSelectedVideoId(video.id)
                        setHeadVideoIndex(vidInd)
                        dispatch(setIsFirstVideoSelected(true))
                      }
                    }}
                    sx={{
                      backgroundColor: blackouts[vidInd] ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)',
                      width: 'calc(100% - 30px)',
                    }}
                  >
                    <TickMark src={Tick} alt="Tick mark icon" />
                  </VideoSelectorOverlay>
                )}
              </VideoBox>
            )
          })}
        </SideVideosContainer>
      </DesktopVideosFrame>

      <VideoPlayerControl>
        <PlayerControls
          currentlyAtRef={currentlyAtRef}
          showCPanel={showCPanel}
          seekTo={seekTo}
          progressAt={progressAt}
          setProgressAt={setProgressAt}
          pressPlayPause={pressPlayPause}
          muteUnmute={muteUnmute}
          handleVolumeSlide={handleVolumeSlide}
          fullScreenVideoElem={fullScreenVideoElem}
          openSpPicker={openSpPicker}
          popoverId={popoverId}
          isSpeedPopUp={isSpeedPopUp}
          anchorElem={anchorElem}
          closeSpPicker={closeSpPicker}
          handleSpeedPick={handleSpeedPick}
          toggleFullScreen={toggleFullScreen}
          popoverVolumeId={popoverVolumeId}
          isVolumePopUp={isVolumePopUp}
          anchorElemVolume={anchorElemVolume}
          openSpVolumePicker={openSpVolumePicker}
          closeSpVolumePicker={closeSpVolumePicker}
        />
      </VideoPlayerControl>
    </>
  )
}

export default RemixDeskVids

const DesktopVideosFrame = styled('div')({
  width: '100%',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
})
const HeadVideoContainer = styled('section')({
  height: '100%',
})
const ControlsTop = styled('div')({
  width: '100%',
  height: '38%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})
const TimeBox = styled(Box)({
  width: '100%',
  height: '50px',
  textAlign: 'left',
  padding: 0,
  margin: 0,
})
const ControlsMiddle = styled('div')({
  width: '100%',
  height: '24%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
const PlayPauseBox = styled(Box)({
  width: '50px',
  height: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  right: '5px',
})
const ControlsBottom = styled('div')({
  width: '100%',
  height: '38%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const ProgressBox = styled(Box)({
  width: '100%',
  margin: 0,
  padding: 0,
})
const BottomButtons = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const LeftBB = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const RightBB = styled('div')({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})
const PopOverMenu = styled(Popover)({
  backgroundColor: 'transparent',
  fontSize: '12px',
})
const PlaybackSpeedList = styled(List)({
  '&.MuiList-root': {
    backgroundColor: '#333333',
  },
})
const PlaybackSpeedListButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#333333',
  '&:hover': {
    backgroundColor: '#4d4d4d',
  },
})
const SideVideosContainer = styled('section')({
  height: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  overflow: 'auto',
  overflowX: 'hidden',
  scrollbarColor: '#595959 #ffffff',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#595959',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#666666',
  },
})
const VideoBox = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: 100,
  margin: '0 0 3px 0',
  padding: 0,
  cursor: 'pointer',
  '&:last-child': {
    margin: 0,
  },
})
const Video = styled('video')({
  position: 'absolute',
  zIndex: 200,
  borderRadius: '10px',
})
const VideoSelectorOverlay = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 400,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  cursor: 'pointer',
  borderRadius: '10px',
})
const VideoControls = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 500,
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: '30px 25px 10px 25px',
  backgroundColor: 'transparent',
  borderRadius: '10px',
})
const TickMark = styled('img')({
  opacity: 0,
  transition: 'opacity 0.3s ease',
  margin: '15px 15px 0 0',
})

const VideoPlayerControl = styled('div')({
  width: '100%',
  margin: '14px 0',
})
