import { useSelector } from 'react-redux'
import { Typography, Grid, Container } from '@mui/material'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import type { RootState } from 'store/index'

function About() {
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const locs = localizationDict[appLanguage]

  return (
    <Container>
      <Grid container spacing={2} flexDirection="column" my={2.5}>
        <Grid item>
          <Typography variant="subtitle1">{locs?.title}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" color="text.secondary" component="pre">
            {parse(sanitizeHtml(locs?.description || ''))}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default About
