import { useSelector } from 'react-redux'
import { Typography, Grid } from '@mui/material'
import parse from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'
import type { RootState } from 'store/index'

function About() {
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const localizationDict = useSelector((state: RootState) => state.appLanguage.localizationDict)
  const locs = localizationDict[appLanguage]

  return (
    <Grid container spacing={2} flexDirection="column" my={2.5}>
      <Grid item>
        <Typography variant="subtitle1" color="text.secondary" letterSpacing={0.5}>
          {locs?.title}
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontSize={18} component="pre">
          {parse(sanitizeHtml(locs?.description || ''))}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default About
