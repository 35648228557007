import { createTheme } from '@mui/material/styles'

const appTheme = createTheme({
  typography: {
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    background: {
      default: '#16181A',
    },
    text: {
      primary: '#ffffff',
      secondary: '#808080',
    },
  },
})

export default appTheme
