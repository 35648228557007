import { useState, useEffect, useRef, useCallback, SyntheticEvent, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useParams, useSearchParams } from 'react-router-dom'
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import * as Shaka from 'shaka-player/dist/shaka-player.compiled'
import { v4 as uuidv4 } from 'uuid'
import jwtDecode from 'jwt-decode'
import { UAParser } from 'ua-parser-js'
import { styled } from '@mui/system'
import PortablePanel from 'pages/Portable/MainPanel'
import PortableSkeleton from 'pages/Portable/SkeletonLoader'
import DesktopPanel from 'pages/Desktop/MainPanel'
import DesktopSkeleton from 'pages/Desktop/SkeletonLoader'
import AbortModal from 'components/AbortScreen'
import { useGetLearnObjByIdQuery } from 'services/api'
import {
  storeLearnObj,
  storeChapters,
  storeMediaTracks,
  storeContentType,
} from 'store/reducers/learningObject'
import {
  setHeadVideoId,
  setHeadVideoIndex,
  deviceIsPortable,
  screenIsInPortrait,
  getScreenWidth,
  getScreenHeight,
  showAbortModal,
  showJustOneTrack,
  setUpVideoLoader,
  setHiddens,
  setBlackouts,
  toggleVideoLoader,
  toggleVideoControls,
  toggleBlackout,
  toggleHidden,
} from 'store/reducers/componentDisplay'
import { storeDeviceInfo } from 'store/reducers/deviceInfo'
import { HHMMSSToSec, secToHHMMSS } from 'utils/dateTimeConfig'
import { findAtom } from 'utils/atomLookup'
import { storeUserData } from 'store/reducers/userData'
import {
  chooseSubtitle,
  storeMediaDuration,
  storeAtomStarts,
  storeAtoms,
  pressPlayFirstTime,
  togglePlayback,
  setCurrentlyAt,
} from 'store/reducers/playback'
import { stroreLangCodes, storeLocLabels, storeLocalizationDict } from 'store/reducers/appLanguage'
import SpentTimeTracker from 'components/SpentTimeTracker'
import AlertMsg from 'services/alertMsg'
import { transformLocLabels } from 'services/language'
import type { RootState } from 'store/index'
import { TLangCodes, TAtom, IAtoms } from 'store/storeTypes'
import { PreviewContext } from 'contexts/PreviewContext'
import Cookies from 'js-cookie'

type TUserData = {
  [key: string]: string
}
export interface IMaxDims {
  width: number
  height: number
}

const MainSection = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

function AppFrame() {
  const routeParam = useParams()
  const [queryParams, setQueryParams] = useSearchParams()

  const [selectedVideoId, setSelectedVideoId] = useState<string>('')

  const [isPressExitButton, setIsPressExitButton] = useState<boolean>(false)
  const [isPlayerSeeking, setIsPlayerSeeking] = useState<boolean>(false)
  const [isSyncSignal, setIsSyncSignal] = useState<boolean>(false)
  const [isFirstPlayed, setIsFirstPlayed] = useState<boolean>(false)

  const appBaseUrl = useSelector((state: RootState) => state.apiURLs.appBase)
  const playbackUrl = useSelector((state: RootState) => state.apiURLs.playback)
  const reportingUrl = useSelector((state: RootState) => state.apiURLs.reporting)
  const contentType = useSelector((state: RootState) => state.learningObject.type)
  const mediaTracks = useSelector((state: RootState) => state.learningObject.mediaTracks)
  const atomMatrix = useSelector((state: RootState) => state.playback.atomMatrix)
  const atomStarts = useSelector((state: RootState) => state.playback.atomStarts)
  const playbackSpeed = useSelector((state: RootState) => state.playback.playbackSpeed)
  const isUserSeeking = useSelector((state: RootState) => state.playback.isUserSeeking)
  const isBuffering = useSelector((state: RootState) => state.playback.isBuffering)
  const isFirstPlayPressDone = useSelector(
    (state: RootState) => state.playback.isFirstPlayPressDone,
  )
  const isPlaying = useSelector((state: RootState) => state.playback.isPlaying)
  const isFirstVideoSelected = useSelector(
    (state: RootState) => state.componentDisplay.isFirstVideoSelected,
  )
  const headVideoId = useSelector((state: RootState) => state.componentDisplay.headVideoId)
  const headVideoIndex = useSelector((state: RootState) => state.componentDisplay.headVideoIndex)
  const volumeLevel = useSelector((state: RootState) => state.playback.volumeLevel)
  const blackouts = useSelector((state: RootState) => state.componentDisplay.blackouts)
  const hiddens = useSelector((state: RootState) => state.componentDisplay.hiddens)
  const isPortableDevice = useSelector(
    (state: RootState) => state.componentDisplay.isPortableDevice,
  )
  const isAbortModal = useSelector((state: RootState) => state.componentDisplay.isAbortModal)
  const appLanguage = useSelector((state: RootState) => state.appLanguage.selected)
  const subtitleLang = useSelector((state: RootState) => state.playback.subtitleLang)
  const browser = useSelector((state: RootState) => state.deviceInfo.browser)
  const device = useSelector((state: RootState) => state.deviceInfo.device)

  const isSafari = browser?.name?.toLowerCase().includes('safari')
  const isiOS = device?.model?.toLowerCase().includes('iphone')

  const dispatch = useDispatch()

  interface IOneFetch {
    areSubs: boolean
    areLangCodes: boolean
    areLabels: boolean
  }
  const oneFetch = useRef<IOneFetch>({
    areSubs: false,
    areLangCodes: false,
    areLabels: false,
  })
  const isPreview = useRef<boolean>(false)
  const VideoRefs = useRef<HTMLVideoElement[]>([])
  const socket = useRef<WebSocket>()
  const ctrlPanelTimeout = useRef<number>(0)
  const currentlyAtRef = useRef<number>(0)
  const isShakaInstalledOk = useRef<boolean>(false)
  const shakaInstances = useRef<Shaka.Player[]>([])
  const maxVideoSizeForVariant = useRef<IMaxDims>({ width: 0, height: 0 })
  const atomsState = useRef<TAtom[]>([])
  const isPlaybackOn = useRef<boolean>(false)
  const subtitleRefs = useRef<HTMLParagraphElement[]>([])
  const textTracksWithEventListeners = useRef<string[]>([])

  const isPortraitView = useMediaQuery({ query: '(orientation: portrait)' })
  const isMobile = new UAParser().getResult().device.type === 'mobile'

  const showCPanel = (isTemporary: boolean, keyEvent?: SyntheticEvent<HTMLDivElement>): void => {
    if (keyEvent) {
      keyEvent.preventDefault()
      keyEvent.stopPropagation()
    }
    clearTimeout(ctrlPanelTimeout.current)
    dispatch(toggleVideoControls(true))
    if (isTemporary) {
      ctrlPanelTimeout.current = window.setTimeout(() => dispatch(toggleVideoControls(false)), 3500)
    }
  }

  const hideCPanel = () => {
    clearTimeout(ctrlPanelTimeout.current)
    dispatch(toggleVideoControls(false))
  }

  const getCurrentAtom = (vidInd: number, timePos: number) => {
    const time = Math.floor(timePos) * 1000

    const currentAtomStart = atomStarts[vidInd][findAtom(atomStarts[vidInd], time)]
    const currentAtom = atomMatrix[currentAtomStart?.toString()]?.[vidInd]

    if (currentAtom?.behaviour?.videoSetting === 'Loop') {
      const selectedVideo = VideoRefs.current[vidInd]

      if (selectedVideo) {
        if (timePos <= currentAtom?.duration) {
          if (selectedVideo.currentTime >= currentAtom.to) {
            selectedVideo.currentTime = currentAtom.from
          }
        }
      }
    }

    if (currentAtom) {
      return {
        currentAtomStart,
        currentAtom: currentAtom,
      }
    } else {
      return {
        currentAtomStart: 0,
        currentAtom: null,
      }
    }
  }

  const goTo = (toWhere: number) => {
    const contentTrackInd = mediaTracks?.findIndex((track: any) => track.contentTrack)
    const contentTrack = VideoRefs?.current?.[contentTrackInd]
    contentTrack.pause()

    contentTrack.currentTime = toWhere
    currentlyAtRef.current = toWhere

   dispatch(setCurrentlyAt(toWhere))

    const canContinuePlay = new Array(VideoRefs.current.length).fill(false)
    
    VideoRefs.current.forEach((video: HTMLVideoElement, vidInd: number) => {
      const { currentAtom } = getCurrentAtom(vidInd, toWhere)

      if (currentAtom) {
        const selectedVideo = VideoRefs.current[currentAtom.trackIndex]

        if (
          !currentAtom?.play &&
          currentAtom?.isPaused &&
          Math.floor(toWhere) >= currentAtom.to &&
          Math.floor(toWhere) <= currentAtom.end &&
          currentAtom?.behaviour?.videoSetting === 'Pause'
        ) {
          video.currentTime = toWhere

          selectedVideo.currentTime = currentAtom.to
          selectedVideo.pause()
        } else {          
          video.pause()
          video.currentTime = toWhere

          video.addEventListener('canplaythrough', () => {
            canContinuePlay[vidInd] = true
            if (canContinuePlay.every((canPlay) => canPlay)) {
              pressPlayPause(isFirstPlayPressDone)
            }
          }, {once: true})
        }

          
        if (!browser?.name?.toLowerCase().includes('safari')) {
         syncTracks(video, currentAtom, toWhere)
        }

        alignTrackVisibility(currentAtom, video.id, vidInd)
      }
    })
  }

  const seekTo = (toWhere: number, isPlaying?: boolean): void => {
    if (Number.isNaN(toWhere) || typeof toWhere !== 'number') return

    goTo(toWhere)

    setIsPlayerSeeking(true)
  }

  const alignTrackVisibility = (currentAtom: TAtom, videoId: string, vidInd: number): void => {
    if (currentAtom.see === 'Show') {
      if (hiddens[vidInd]) dispatch(toggleHidden({ index: vidInd, to: false }))
      if (blackouts[vidInd]) dispatch(toggleBlackout({ index: vidInd, to: false }))
    } else if (currentAtom.see === 'Blackout') {
      if (hiddens[vidInd]) dispatch(toggleHidden({ index: vidInd, to: false }))
      dispatch(toggleBlackout({ index: vidInd, to: true }))
    } else if (currentAtom.see === 'Hide') {
      if (blackouts[vidInd]) dispatch(toggleBlackout({ index: vidInd, to: false }))
    }

    if (mediaTracks?.[vidInd]?.contentTrack) {
      dispatch(toggleHidden({ index: vidInd, to: true }))
    }

   handlePlayback(currentAtom, videoId)
  }

  const handlePlayback = (currentAtom: TAtom, videoId: string): void => {
    if (currentAtom.head) {
      if (!isFirstVideoSelected && isPlaybackOn.current) {
        setSelectedVideoId(videoId)
      }

    }
  }

  const changePlayPauseState = (currentAtom: any, video: any, vidInd: number) => {
    if (currentAtom) {
      if (atomsState?.current) {
        alignTrackVisibility(currentAtom, video.id, vidInd)
        if (currentAtom.play) {
          return video.play()
        } else {
          video.pause()
          return Promise.resolve(false)
        }
      } else {
        video.pause()
        return Promise.resolve(false)
      }
    } else {
      return Promise.resolve(new Error('mainTrack'))
    }
  }

  const playPauseAll = (toPlay: boolean) => {
    setIsFirstPlayed(false)

    Promise.allSettled(
      VideoRefs.current.map((video: HTMLVideoElement, vidInd: number) => {
        if (toPlay) {
          const { currentAtom } = getCurrentAtom(vidInd, currentlyAtRef.current)

          if (vidInd > 0) changePlayPauseState(currentAtom, video, vidInd)
        } else {
          video.pause()
          return Promise.resolve(false)
        }
      }),
    )
      .then(() => {
        VideoRefs.current.forEach((video: HTMLVideoElement) => {
          if (toPlay) {
            return video?.play()
          } else {
            video?.pause()
            return Promise.resolve(false)
          }
        })
      })
      .catch((error) => {
        console.error('error: ', error)
      })
  }

  const firstPressPlay = (toPlay: boolean) => {
    const contentTrackInd = mediaTracks?.findIndex((track: any) => track?.contentTrack)
    const contentTrack = VideoRefs.current[contentTrackInd]

    dispatch(pressPlayFirstTime(true))

    if (contentTrack) {
      VideoRefs.current.forEach((video: HTMLVideoElement) => {
        if (toPlay) {
          video?.play()
        } else {
          video?.pause()
        }
      })
    } else {
      playPauseAll(toPlay)
    }
  }

  const pressPlayPause = (toPlay: boolean): void => {
    showCPanel(toPlay)
    dispatch(togglePlayback(toPlay))
    isPlaybackOn.current = toPlay

    if (!isFirstPlayPressDone) setIsFirstPlayed(true)

    isFirstPlayPressDone ? playPauseAll(toPlay) : firstPressPlay(toPlay)
  }

  const syncTracks = (
    video: HTMLVideoElement,
    currentAtom: TAtom,
    correctPosition: number,
  ): void => {
    if (currentAtom.play) {
      if (!isPlayerSeeking) {
        video.playbackRate = playbackSpeed
      }

      if (isPlayerSeeking) {
        if (video.currentTime < correctPosition - 0.015) {
          video.playbackRate = playbackSpeed + 0.15
        } else if (video.currentTime > correctPosition + 0.015) {
          video.playbackRate = playbackSpeed - 0.15
        } else if (video.playbackRate !== playbackSpeed) {
          video.playbackRate = playbackSpeed
        }
      }    
    }
  }

  const makeLOFetchURL = (): string => {
    const id = routeParam?.contentId
    const isPrev = Array.from(queryParams.keys()).find(
      (key: string) => key.toLowerCase() === 'ispreview',
    )
    isPreview.current = !isPrev ? false : queryParams.get(isPrev)?.toLowerCase() === 'true'

    return isPreview.current ? `no-cache/${id}` : `${id}`
  }

  const {
    data: learnObj,
    error: learnObjError,
    isLoading: learnObjLoading,
    isSuccess: learnObjReady,
  } = useGetLearnObjByIdQuery(makeLOFetchURL())

  useEffect(() => {
    if (learnObjError && !isiOS) {
      AlertMsg.show({ type: 'error', message: "Can't load the content!" })
      if (!isAbortModal.on) dispatch(showAbortModal('wrongparams'))
    }
  }, [learnObjError])

  const handleAtomsPlayPause = (
    currentAtom: TAtom,
    selectedVideo: HTMLVideoElement,
    video: HTMLVideoElement,
    vidInd: number,
    movedTo: number,
  ) => {
    if (
      !currentAtom?.play &&
      currentAtom?.isPaused &&
      Math.floor(movedTo) >= currentAtom.to &&
      currentAtom?.behaviour?.videoSetting === 'Pause' &&
      !browser?.name?.toLowerCase().includes('safari')
    ) {
      if (Math.floor(selectedVideo.currentTime) >= currentAtom.to) {
        selectedVideo.currentTime = currentAtom.to
        selectedVideo.pause()
      } else {
        selectedVideo.play()
      }
    } else {
      atomsState.current[vidInd] = { ...currentAtom }
      alignTrackVisibility(currentAtom, video.id, vidInd)
      if (currentAtom.play) {
        if (video.currentTime !== movedTo) {
          video.currentTime = movedTo
        }
        video.play()
      } else {
        video.pause()
      }
    }
  }

  const updateClipPosition = useCallback((): void => {
    const contentTrackInd = mediaTracks?.findIndex((track: any) => track.contentTrack)
    const movedTo = VideoRefs?.current?.[contentTrackInd]?.currentTime

    VideoRefs.current.forEach((video: HTMLVideoElement, vidInd: number) => {
      const { currentAtom } = getCurrentAtom(vidInd, movedTo)

      if (isPlaybackOn.current) {
        const selectedVideo = currentAtom
          ? VideoRefs.current[currentAtom.trackIndex]
          : VideoRefs.current[0]

        if (
          !currentAtom?.play &&
          currentAtom?.isPaused &&
          Math.floor(selectedVideo.currentTime) >= currentAtom.to &&
          currentAtom.id === atomsState.current[vidInd]?.id
        ) {
          selectedVideo.play()
        }

        if (!!currentAtom && currentAtom?.id !== atomsState.current[vidInd]?.id) {
          if (
            Math.floor(selectedVideo.currentTime) >= currentAtom?.from &&
            Math.floor(selectedVideo.currentTime) <= currentAtom?.to
          ) {
            selectedVideo.currentTime = currentAtom?.from
            selectedVideo.play()
          }

          handleAtomsPlayPause(currentAtom, selectedVideo, video, vidInd, movedTo)
        }

        if (!browser?.name?.toLowerCase().includes('safari') && currentAtom) {
          syncTracks(video, currentAtom, movedTo)
        }
      } else {
        video.pause()
      }
    })

    if (isPortableDevice) {
      if (isUserSeeking || isBuffering) return

      dispatch(setCurrentlyAt(movedTo))
    } else {
      dispatch(setCurrentlyAt(movedTo))
    }
  }, [mediaTracks, playbackSpeed, learnObj?.duration, isPortableDevice, isUserSeeking, isBuffering])

  useEffect(() => {
    if (!isFirstPlayPressDone) return

    if (isFirstPlayPressDone && !isPlaying && currentlyAtRef.current >= 0) {
      VideoRefs.current.map((video: HTMLVideoElement, vidInd: number) => {
        video.currentTime = currentlyAtRef.current

        dispatch(toggleVideoLoader({ vidInd, toLoad: false }))
      })
    }
  }, [isFirstPlayPressDone, isPlaying])

  // PORTABLE DEVICE / Safari - sync videos time after seeking event
  useEffect(() => {
    if (!isFirstPlayPressDone) return

    if (isPortableDevice) {
      if (isUserSeeking) {
        setIsSyncSignal(true)
      }

      if (isSyncSignal && isBuffering && currentlyAtRef.current >= 0) {
        pressPlayPause(false)
        setIsSyncSignal(true)

        VideoRefs.current.map((video: HTMLVideoElement, vidInd: number) => {
          video.currentTime = currentlyAtRef.current

          dispatch(toggleVideoLoader({ vidInd, toLoad: false }))
        })
      }

      if (isSyncSignal && !isBuffering) {
        pressPlayPause(true)
        setIsSyncSignal(false)
      }
    }
  }, [isFirstPlayPressDone, isPortableDevice, isBuffering, isSyncSignal, isUserSeeking])

  // TODO: remove unnecessary subscriptions from \src\pages\Desktop\MainPanel\DeskVideos\RemixDeskVids.tsx
  const trackPlaybackStateChange = (vidInd: number, stateChange: string, toLoad: boolean): void => {
    const isPauseLoad = (isFirstPlayPressDone && !isPlaying) 
    dispatch(
      toggleVideoLoader({ vidInd, toLoad: isPauseLoad || isPortableDevice ? false : toLoad }),
    )
  }

  const castSubtitles = (event: SyntheticEvent<HTMLTrackElement>) => {
    if (subtitleRefs?.current?.length) {
      const track = event.target as any
      subtitleRefs.current.forEach((subPara: HTMLParagraphElement) => {
        subPara.innerHTML =
          track?.activeCues[0]?.text === undefined ? '' : `&nbsp;${track.activeCues[0].text}&nbsp;`
      })
    }
  }

  const displaySubs = (subtitleLang: string) => {
    if (VideoRefs.current?.length > 0) {
      if (subtitleLang === 'Off') {
        subtitleRefs.current.forEach((subPara: HTMLParagraphElement) => {
          if (subPara.innerHTML) {
            subPara.innerHTML = ''
          }
        })
      }
      const video = VideoRefs.current[0]
      Array.from(video?.textTracks)?.forEach((textTrack: any) => {
        if (textTrack) {
          if (subtitleLang === 'Off') {
            textTrack.mode = 'disabled'
          } else if (textTrack?.id === `${subtitleLang}`) {
            if (!textTracksWithEventListeners?.current?.includes(`${subtitleLang}`)) {
              textTrack.addEventListener('cuechange', castSubtitles, true)
              textTracksWithEventListeners?.current.push(`${subtitleLang}`)
            }
            textTrack.mode = 'hidden'
          } else {
            textTrack.mode = 'disabled'
          }
        }
      })
    }
  }

  const plstCastSubtitles = (event: SyntheticEvent<HTMLTrackElement>) => {
    if (subtitleRefs?.current?.length) {
      const track = event.target as any
      const subPara = subtitleRefs.current[headVideoIndex]
      subPara.innerHTML =
        track?.activeCues[0]?.text === undefined ? '' : `&nbsp;${track.activeCues[0].text}&nbsp;`
    }
  }

  const plstDisplaySubs = (subtitleLang: string) => {
    if (VideoRefs.current?.length > 0) {
      const video = VideoRefs.current[headVideoIndex]
      const subPara = subtitleRefs.current[headVideoIndex]
      if (subtitleLang === 'Off') {
        if (subPara.innerHTML) {
          subPara.innerHTML = ''
        }
      }
      Array.from(video.textTracks).forEach((textTrack: any) => {
        if (textTrack) {
          if (subtitleLang === 'Off') {
            textTrack.mode = 'disabled'
          } else if (textTrack.id === `${subtitleLang}`) {
            if (
              !textTracksWithEventListeners?.current?.includes(`${headVideoId}—${subtitleLang}`)
            ) {
              textTrack.addEventListener('cuechange', plstCastSubtitles, true)
              textTracksWithEventListeners?.current.push(`${headVideoId}—${subtitleLang}`)
            }
            textTrack.mode = 'hidden'
          } else {
            textTrack.mode = 'disabled'
          }
        }
      })
    }
  }

  const handlePauseAtoms = (
    atomStarts: number[][],
    atoms: IAtoms,
    clipStart: string,
    tracks: any[],
    trI: number,
    atomData: any,
  ) => {
    const { atom, duration, cueIn, cueOut, currentClip, audioSetting, volumeLevel } = atomData

    if (duration === cueOut) {
      atom.id = uuidv4()
      atom.from = cueIn
      atom.to = cueOut
      atom.play = true
      atom.see = 'Show'
      atom.head = currentClip.main
      atom.behaviour.videoSetting = 'Pause'
      atom.behaviour.audioSetting = audioSetting
      atom.isPaused = false
      atom.duration = duration - cueOut
      const atomAt = (cueOut * 1000).toString()
      if (!atoms[atomAt]) {
        atoms[atomAt] = new Array(tracks.length).fill(null)
      }
      atomStarts[trI].push(parseInt(atomAt, 10))
      atoms[atomAt][trI] = { ...atom }
    }

    if (duration > cueOut) {
      atom.id = uuidv4()
      atom.from = cueIn
      atom.to = cueOut
      atom.play = false
      atom.see = 'Show'
      atom.head = currentClip.main
      atom.behaviour.videoSetting = 'Pause'
      atom.behaviour.audioSetting = audioSetting
      atom.isPaused = true
      atom.duration = duration - cueOut
      const atomAt = clipStart
      if (!atoms[atomAt]) {
        atoms[atomAt] = new Array(tracks.length).fill(null)
      }
      atomStarts[trI].push(parseInt(atomAt, 10))
      atoms[atomAt][trI] = { ...atom }
    } else if (duration <= cueOut) {
      atom.id = uuidv4()
      atom.from = cueIn
      atom.to = cueOut
      atom.play = true
      atom.see = 'Show'
      atom.head = currentClip.main
      atom.behaviour.videoSetting = 'Pause'
      atom.isPaused = false
      atom.duration = duration
      atomStarts[trI].push(parseInt(clipStart, 10))
      atoms[clipStart][trI] = { ...atom }
    }

    atom.behaviour.audioSetting = audioSetting
    atom.behaviour.volumeLevel = volumeLevel
  }

  const prepareAtoms = (
    atomStarts: number[][],
    atoms: IAtoms,
    clipStart: string,
    tracks: any[],
    track: any,
    trI: number,
  ) => {
    if (!atoms[clipStart]) {
      atoms[clipStart] = new Array(tracks.length).fill(null)
    }

    const currentClip = track.clips[clipStart]
    const trackIndex = tracks?.findIndex((trackItem) => trackItem?.id === track?.id)

    const behaviour = currentClip?.behaviour
    const audioSetting = currentClip?.behaviour?.audioSetting
    const volumeLevel = currentClip?.behaviour?.volumeLevel
    const cueIn = HHMMSSToSec(currentClip.cueIn.slice(0, 9))
    const cueOut = HHMMSSToSec(currentClip.cueOut.slice(0, 9))
    const duration = HHMMSSToSec(currentClip.duration.slice(0, 9))

    const atom: TAtom = {
      id: '',
      from: 0,
      to: 0,
      play: false,
      see: '',
      head: false,
      behaviour: {
        videoSetting: '',
        audioSetting: '',
        volumeLevel: '',
      },
      isPaused: false,
      duration: 0,
      trackIndex: 0,
      end: 0,
    }

    atom.trackIndex = trackIndex
    atom.end = Math.floor(parseInt(clipStart) / 1000 + duration)

    if (behaviour.videoSetting === 'Pause') {
      const atomData = {
        atom,
        duration,
        cueIn,
        cueOut,
        currentClip,
        audioSetting,
        volumeLevel,
      }
      handlePauseAtoms(atomStarts, atoms, clipStart, tracks, trI, atomData)
    } else if (behaviour.videoSetting === 'Loop') {
      const duration = HHMMSSToSec(currentClip.duration)
      const loopLength = cueOut - cueIn
      const numberOfLoops = Math.ceil(duration / loopLength)
      let atomAt = clipStart
      for (let i = 0; i < numberOfLoops; i++) {
        if (!atoms[atomAt]) {
          atoms[atomAt] = new Array(tracks.length).fill(null)
        }
        atom.id = uuidv4()
        atom.from = cueIn
        atom.to = cueOut
        atom.play = true
        atom.see = 'Show'
        atom.head = currentClip.main
        atom.duration = duration
        atom.behaviour.videoSetting = 'Loop'
        atom.behaviour.audioSetting = audioSetting
        atom.behaviour.volumeLevel = volumeLevel
        atom.isPaused = false
        atomStarts[trI].push(parseInt(atomAt, 10))
        atoms[atomAt][trI] = { ...atom }
        atomAt = (parseInt(atomAt, 10) + loopLength).toString()
      }
    } else if (behaviour.videoSetting === 'Blackout' || behaviour.videoSetting === 'Hide') {
      atom.id = uuidv4()
      atom.from = cueIn
      atom.to = cueOut
      atom.play = false
      atom.see = behaviour.videoSetting === 'Blackout' ? 'Blackout' : 'Hide'
      atom.head = currentClip.main
      atom.duration = duration
      atom.behaviour.videoSetting = behaviour.videoSetting === 'Blackout' ? 'Blackout' : 'Hide'
      atom.behaviour.audioSetting = audioSetting
      atom.behaviour.volumeLevel = volumeLevel
      atom.isPaused = false
      atomStarts[trI].push(parseInt(clipStart, 10))
      atoms[clipStart][trI] = { ...atom }
    }

    atomStarts[trI].sort((clipA: number, clipB: number) => clipA - clipB)
  }

  const calcHiddenTrackNumber = (tracks: any[], atomStarts: number[][], atoms: IAtoms) => {
    const allAtomsHidden = tracks.map(() => 1)
    atomStarts.forEach((starts: number[], index: number) => {
      for (const start of starts) {
        const atom = atoms[start.toString()][index]
        if (atom.see !== 'Hide') {
          allAtomsHidden[index] = 0
          break
        }
      }
    })
    return allAtomsHidden.reduce((hideTrack: number, hide: number) => {
      hideTrack += hide
      return hideTrack
    }, 0)
  }

  const makeReportReqParams = (jwtToken: string, userData: any): AxiosRequestConfig => {
    return {
      baseURL: reportingUrl,
      url: '/clientreport',
      method: 'post',
      data: {
        Properties: [
          {
            Name: 'Type',
            Value: 'ContentHitContract',
          },
          {
            Name: 'UserToken',
            Value: jwtToken,
          },
          {
            Name: 'ContentToken',
            Value: learnObj.contentToken,
          },
          {
            Name: 'ContentRef',
            Value: learnObj.id,
          },
          {
            Name: 'Genre',
            Value: learnObj.genre,
          },
          {
            Name: 'Category',
            Value: learnObj.category,
          },
          {
            Name: 'UserRef',
            Value: userData.nameid,
          },
          {
            Name: 'OrganizationRef',
            Value: learnObj.organizationRef,
          },
          {
            Name: 'Date',
            Value: new Date().toISOString(),
          },
        ],
      },
    }
  }

  const getReturnURL = useCallback(() => {
    let returnURL: string = Array.from(queryParams.keys()).reduce(
      (backURL: string, key: string): string => {
        return key.toLowerCase() === 'returnurl' ? (queryParams.get(key) as string) : backURL
      },
      '',
    )
    try {
      returnURL = window.decodeURIComponent(returnURL)
    } catch (error) {
      returnURL = 'http://www.nexius.hu'
    }
    return returnURL
  }, [queryParams])

  const handleShakaError = (event: any): void => {
    AlertMsg.show({
      type: 'error',
      message: event?.detail?.code,
      context: 'Shaka Player error → ',
    })
  }

  const createTrackSkeleton = (
    id: string,
    duration: number,
    poster: string,
    published: boolean,
  ) => {
    return {
      id,
      main: false,
      contentId: learnObj.id,
      duration: duration ?? 1000,
      poster,
      published,
      clips: {
        '0': {
          main: false,
          cueIn: '00:00:00',
          cueOut: secToHHMMSS(duration / 1000),
          duration: secToHHMMSS(duration / 1000),
          behaviour: {
            videoSetting: 'Pause',
            audioSetting: 'On',
            volumeLevel: '7',
          },
        },
      },
    }
  }

  // Playlist object related functions
  const initOneTrack = (tracks: any[], trInd: number, video: HTMLVideoElement) => {
    let track = mediaTracks[trInd]
    track = createTrackSkeleton(track.id, track.duration, track.poster, track.published)
    dispatch(storeMediaDuration(track.duration / 1000))

    return axios({
      baseURL: appBaseUrl,
      url: `${playbackUrl}/${track.id}`,
      method: 'post',
      data: {
        format: 'DASH',
        subtitleFormat: 'WEBVTT',
      },
    }).catch(async (error: AxiosError) => {
        console.error("Couldn't get content playback with DASH → ", error)

        if (!(isSafari || isiOS)) {
          try {
            return await axios({
              baseURL: appBaseUrl,
              url: `${playbackUrl}/${track.id}`,
              method: 'post',
              data: {
                format: 'HLS',
                subtitleFormat: 'WEBVTT',
              },
            })
          } catch (error) {
            console.error("Couldn't get content playback with HLS → ", error)
          }
        }
    }).then((response) => {
        const mediaItem = { ...track, ...response?.data, initialized: true }
        const offSubtitle = {
          locale: 'Off',
          label: 'SUBTITLE:Off',
          url: null,
        }
        mediaItem.subtitles = [offSubtitle, ...mediaItem.subtitles]
        const tracksCopy = [...tracks]
        tracksCopy.splice(trInd, 1, mediaItem)
        dispatch(storeMediaTracks(tracksCopy))

        shakaInstances.current[trInd] = new Shaka.Player()
        shakaInstances.current[trInd].attach(video)
        const player = shakaInstances.current[trInd]
        player.addEventListener('error', (error: any) => handleShakaError(error))
        video.volume = volumeLevel / 100
        return player.load(mediaItem.url)
      })
  }

  const continueWithSetSubs = (headVidInd: number, subtLangCopy: string) => {
    if (VideoRefs.current[headVidInd]?.textTracks) {
      const textTracks = Array.from(VideoRefs.current[headVidInd]?.textTracks)
      if (textTracks.find((textTrack: any) => textTrack.id === subtLangCopy)) {
        dispatch(chooseSubtitle(subtLangCopy))
      }
    } else {
      dispatch(chooseSubtitle('Off'))
    }
  }

  const plstPlayPause = (toPlay: boolean, vidInd?: number): any => {
    // Portable Playlist case
    if (typeof vidInd === 'number' && vidInd !== headVideoIndex) {
      const subtLangCopy = subtitleLang
      dispatch(chooseSubtitle('Off'))
      VideoRefs.current.forEach((video: HTMLVideoElement) => video.pause())
      const video = VideoRefs.current[vidInd]
      dispatch(setHeadVideoId(mediaTracks[vidInd].id))
      dispatch(setHeadVideoIndex(vidInd))
      dispatch(storeMediaDuration(learnObj?.duration))
      dispatch(togglePlayback(true))
      if (!isFirstPlayPressDone) dispatch(pressPlayFirstTime(true))
      isPlaybackOn.current = true

      if (shakaInstances.current[vidInd] === null) {
        return initOneTrack(mediaTracks, vidInd, video)
          .then(() => {
            video.playbackRate = playbackSpeed
            return video?.play()
          })
          .then(() => setTimeout(() => continueWithSetSubs(vidInd, subtLangCopy), 200))
          .catch((error: any) => {
            AlertMsg.show({ type: 'error', message: error })
            if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
          })
          .finally(() => dispatch(toggleVideoLoader({ vidInd: -1, toLoad: false })))
      } else {
        dispatch(storeMediaDuration(mediaTracks[vidInd].duration / 1000))
        video?.play().then(() => setTimeout(() => continueWithSetSubs(vidInd, subtLangCopy), 200))
      }
      // Desktop, Portable - Playlist, Remix
    } else {
      showCPanel(toPlay)
      dispatch(togglePlayback(toPlay))
      if (toPlay && !isFirstPlayPressDone) dispatch(pressPlayFirstTime(true))
      isPlaybackOn.current = toPlay
      const video = VideoRefs.current[headVideoIndex]
      toPlay ? video?.play() : video?.pause()
    }
  }

  // TODO: remove unnecessary subscriptions from \src\pages\Desktop\MainPanel\DeskVideos\PlaylistDeskVids.tsx
  const plstPlaybackStateChange = (vidInd: number, stateChange: string, toLoad: boolean): void => {
    dispatch(toggleVideoLoader({ vidInd, toLoad }))
  }

  const plstSeekTo = (toWhere: number, isPlaying?: boolean, fromContentId?: string): void => {
    if (Number.isNaN(toWhere) || typeof toWhere !== 'number') return

    let newHeadIndex = headVideoIndex
    if (fromContentId) {
      newHeadIndex = mediaTracks.findIndex((track: any) => track.id === fromContentId)
      setSelectedVideoId(fromContentId)
    }
    if (isPlaying) {
      plstPlayPause(false)
      setTimeout(() => plstPlayPause(true), 250)
    }

    const video = VideoRefs.current[newHeadIndex] || VideoRefs.current[0]

    if (video) {
      VideoRefs.current[newHeadIndex].currentTime = toWhere
      dispatch(setCurrentlyAt(toWhere))
      currentlyAtRef.current = toWhere
    }
  }

  const updateCurrentTime = (vidInd: number) => {
    const atWhere = VideoRefs.current[vidInd].currentTime
    dispatch(setCurrentlyAt(atWhere))
    currentlyAtRef.current = atWhere
  }

  useEffect(() => {
    let token: string | null | undefined = queryParams.get('token')
    if (token) {
      Cookies.set('token', token)
      queryParams.delete('token')
      setQueryParams(queryParams)
    }
    token = Cookies.get('token')
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }, [queryParams])

  useEffect(() => {
    if (!isShakaInstalledOk.current) {
      const deviceInfo = new UAParser().getResult()
      dispatch(storeDeviceInfo(deviceInfo))

      Shaka.polyfill.installAll()
      isShakaInstalledOk.current = Shaka.Player.isBrowserSupported()
      console.info(
        `Browser: %c${deviceInfo?.browser?.name} %c[${
          isShakaInstalledOk.current ? 'Supported' : 'Not supported'
        }]`,
        'font-weight: bold',
        `font-style: italic; color: ${
          isShakaInstalledOk.current ? '#228b22' : '#b22222'
        }; font-size: 8px`,
      )

      if (!isShakaInstalledOk.current) {
        AlertMsg.show({
          type: 'error',
          message: `This web browser (${deviceInfo?.browser?.name}) is not supported by the video player!`,
        })
        if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
      }
    }
  }, [])

  const handleResponseTracks = (responseTracks: any) => {
    const contentTrackInd = responseTracks?.findIndex((track: any) => track?.value?.contentTrack)
    const noContentTracks = responseTracks?.filter((track: any) => !track?.contentTrack)

    const atoms: IAtoms = {}
    const atomStarts: number[][] = noContentTracks.map(() => new Array(0))

    const tracks = responseTracks
      .map((track: any) => {
        if (track.status === 'fulfilled') {
          return track.value
        } else {
          console.error(track)
          throw new Error(`Can't get the media element from → ${track?.reason?.config?.url}`)
        }
      })
      .filter((track: any) => track)
      .map((track: any, trI: number, tracks: any) => {
        if (trI === 0) {
          const offSubtitle = {
            locale: 'Off',
            label: 'SUBTITLE:Off',
            url: null,
          }

          if (contentTrackInd !== -1) {
            const contentTrack = tracks[contentTrackInd]

            track.subtitles = contentTrack?.subtitles?.length
              ? [offSubtitle, ...contentTrack.subtitles]
              : [offSubtitle]
          }
        }
        Object.keys(track.clips).forEach((clipStart: string) => {
          prepareAtoms(atomStarts, atoms, clipStart, tracks, track, trI)
        })

        return track
      })

    dispatch(storeAtomStarts(atomStarts))
    dispatch(storeAtoms(atoms))
    atomsState.current = [...atoms[0]]

    const headVideoId =
      tracks[atomsState.current.findIndex((atom: any) => atom?.head)]?.id || tracks[0]?.id
    dispatch(setHeadVideoId(headVideoId))

    dispatch(setUpVideoLoader(noContentTracks.length))

    const blackHiddens = new Array(noContentTracks.length).fill(false)
    const hiddenTracksNum: number = calcHiddenTrackNumber(noContentTracks, atomStarts, atoms)

    dispatch(showJustOneTrack(hiddenTracksNum === noContentTracks.length - 1))
    dispatch(storeMediaTracks(responseTracks?.map((track: any) => track.value)))
    dispatch(setHiddens(blackHiddens))
    dispatch(setBlackouts(blackHiddens))

    showCPanel(false)
  }

  const handleSetTracks = async (mainTrack: any) => {
    let contentTrack: any = null
    if (learnObj.type === 'RemixV2' || learnObj.type === 'Remix') {
      let defaultTrackData
      try {
        defaultTrackData = await axios({
          baseURL: appBaseUrl,
          url: `${playbackUrl}/${learnObj.id}`,
          method: 'post',
          data: {
            format: 'DASH',
            subtitleFormat: 'WEBVTT',
          },
        })
      } catch (error) {
        console.error("Couldn't get content playback with DASH → ", error)
        try {
          defaultTrackData = await axios({
            baseURL: appBaseUrl,
            url: `${playbackUrl}/${learnObj.id}`,
            method: 'post',
            data: {
              format: 'HLS',
              subtitleFormat: 'WEBVTT',
            },
          })
        } catch (error) {
          console.error("Couldn't get content playback with HLS → ", error)
        }
      }

      if (defaultTrackData?.data?.url) {
        const defaultTrack: any = createTrackSkeleton(
          defaultTrackData?.data?.contentId,
          learnObj?.duration,
          mainTrack?.landscapeImage,
          true,
        )

        defaultTrack.landscapeImage = mainTrack?.landscapeImage
        defaultTrack.contentTrack = true
        contentTrack = defaultTrack
      }
    }

    dispatch(storeMediaDuration(learnObj?.duration / 1000))

    Promise.allSettled(
      !contentTrack
        ? learnObj.tracks
        : [...learnObj.tracks, contentTrack].map(async (track: any) => {
            let mediaItem
            try {
              mediaItem = await axios({
                baseURL: appBaseUrl,
                url: `${playbackUrl}/${track.id}`,
                method: 'post',
                data: {
                  format: 'DASH',
                  subtitleFormat: 'WEBVTT',
                },
              })
          } catch (error ) {
              console.error("Couldn't get track playback with DASH → ", error)

              try {
                mediaItem = await axios({
                  baseURL: appBaseUrl,
                  url: `${playbackUrl}/${track.id}`,
                  method: 'post',
                  data: {
                    format: 'HLS',
                    subtitleFormat: 'WEBVTT',
                  },
                })
              } catch (error) {
                console.error("Couldn't get track playback with HLS → ", error)
              }
            }

            return mediaItem
              ? {
                  ...mediaItem.data,
                  ...track,
                }
              : null
          }),
    )
      .then((responseTracks: any) => handleResponseTracks(responseTracks.filter(Boolean)))
      .catch((error: any) => {
        AlertMsg.show({
          type: 'error',
          message: error,
        })
        if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
      })
  }

  const handleTracks = async () => {
    dispatch(storeChapters([
      ...(learnObj.chapters || []),
      ...(learnObj.localizedChapters || []),
    ]))
    const firstMainTrack =
      learnObj?.tracks?.find((track: any) => track.main) || learnObj?.tracks?.[0]
    const clips: any[] = firstMainTrack ? Object.values(firstMainTrack?.clips) : []
    const duration = clips?.length
      ? clips?.reduce((totalDuration, clip) => {
          const clipDurationInSeconds = HHMMSSToSec(clip?.duration)
          return totalDuration + clipDurationInSeconds
        }, 0)
      : 0

    const mainTrack = {
      ...firstMainTrack,
      duration: duration ? duration * 1000 : 0,
    }

    if (!mainTrack) {
      AlertMsg.show({
        type: 'error',
        message: 'There is a problem with the main media element!',
      })
      if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
    } else {
      await handleSetTracks(mainTrack)
    }
  }

  const handleUserTokenData = () => {
    const token = Cookies.get('token')
    let userData: TUserData = {}

    try {
      userData = jwtDecode(token as string)
    } catch (error) {
      if (!isiOS) {
        AlertMsg.show({
          type: 'error',
          message: 'The user token is not valid!',
        })
        if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
      }
    }

    if (token && userData) {
      dispatch(
        storeUserData({
          id: userData.nameid,
          name: userData.unique_name,
          email: userData.email,
          role: userData.role,
          token
        }),
      )

      if (!isPreview.current) {
        axios(makeReportReqParams(token, userData)).catch((error) => {
          console.error("Couldn't connect to client report API → ", error)
        })
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (learnObj) {
        dispatch(storeLearnObj(learnObj))
        dispatch(
          storeLocalizationDict(
            learnObj.localizations.reduce((localizationDict: any, about: any) => {
              localizationDict[about.locale] = { ...about }
              return localizationDict
            }, {}),
          ),
        )
        dispatch(storeContentType(learnObj.type))

        const token = Cookies.get('token')
        if (token && learnObj.duration > 0) {
          const expiresMs = Math.max(learnObj.duration * 2, 1000 * 60 * 60 * 2) // at least 2h
          const expires = new Date(new Date().getTime() + expiresMs)
          Cookies.set('token', token, { expires })
        }

        if (['Remix', 'RemixV2'].includes(learnObj.type)) {
          await handleTracks()
        } else if (!learnObj.tracks?.length) {
          AlertMsg.show({
            type: 'error',
            message: 'There are no elements in the playlist!',
          })
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
        } else if (!learnObj.tracks.every((track: any) => typeof track.duration === 'number')) {
          AlertMsg.show({
            type: 'error',
            message: 'All tracks should have a valid duration!',
          })
          if (!isAbortModal.on) dispatch(showAbortModal('playabort'))
        } else {
          dispatch(
            storeMediaTracks(
              learnObj.tracks.map((track: any) => {
                const parsedTrack = { ...track }
                parsedTrack.poster = `${track.landscapeImage}?preventCache=${uuidv4()}`
                delete parsedTrack.landscapeImage
                return parsedTrack
              }),
            ),
          )
          dispatch(setHeadVideoId(learnObj.tracks[0].id))
          dispatch(setHeadVideoIndex(0))
          showCPanel(false)
        }

        handleUserTokenData()
      }
    }

    fetchData()
  }, [learnObj])

  useEffect(() => {
    if (!oneFetch.current.areLangCodes) {
      oneFetch.current.areLangCodes = true
      axios
        .get(`${window.__RUNTIME_CONFIG__.BASE_URL}/api/v1.0/App/Languages`)
        .then((response: AxiosResponse) => {
          type TLangCode = {
            locale: string
            value: string
          }
          const langCodes = response.data.reduce((lCodes: TLangCodes, langCode: TLangCode) => {
            lCodes[langCode.locale] = langCode.value
            return lCodes
          }, {})
          dispatch(stroreLangCodes(langCodes))
        })
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'warning',
            message: error,
            context: "Can't retrieve the language key codes → ",
          })
        })
    }

    if (!oneFetch.current.areLabels) {
      oneFetch.current.areLabels = true
      axios
        .get(
          `${window.__RUNTIME_CONFIG__.BASE_URL}/api/v1.0/App/Localizations?locale=${appLanguage}`,
        )
        .then((response: AxiosResponse) => dispatch(storeLocLabels(transformLocLabels(response))))
        .catch((error: AxiosError) => {
          AlertMsg.show({
            type: 'warning',
            message: error,
            context: "Can't retrieve the localization information → ",
          })
        })
    }
  }, [])

  useEffect(() => {
    dispatch(screenIsInPortrait(isPortraitView))
  }, [isPortraitView])

  useEffect(() => {
    dispatch(getScreenWidth(window.innerWidth))
    dispatch(getScreenHeight(window.innerHeight))

    const onResizeListener = () => {
      dispatch(getScreenWidth(window.innerWidth))
      dispatch(getScreenHeight(window.innerHeight))
    }
    window.addEventListener('resize', onResizeListener)

    return () => window.removeEventListener('resize', onResizeListener)
  }, [])

  useEffect(() => {
    const area = window.screen.width * window.screen.height
    const isPortable =
      isMobile || (window.screen[isPortraitView ? 'width' : 'height'] < 768 && area < 290000)

    dispatch(deviceIsPortable(isPortable))
    const rootDiv = document.getElementById('root')
    if (!isPortable && rootDiv) {
      rootDiv.style.minWidth = '700px'
    }
  }, [])

  const videoProps = useMemo(
    () => ({
      VideoRefs,
      selectedVideoId,
      setSelectedVideoId,
      showCPanel,
      hideCPanel,
      currentlyAtRef,
      isShakaInstalledOk,
      shakaInstances,
      maxVideoSizeForVariant,
      subtitleRefs,
      castSubtitles,
      displaySubs: ['Remix', 'RemixV2'].includes(contentType) ? displaySubs : plstDisplaySubs,
      pressPlayPause: ['Remix', 'RemixV2'].includes(contentType) ? pressPlayPause : plstPlayPause,
      updateClipPosition,
      trackPlaybackStateChange,
      seekTo: ['Remix', 'RemixV2'].includes(contentType) ? seekTo : plstSeekTo,
      getReturnURL,
      isPreview,
      initOneTrack,
      plstPlaybackStateChange,
      updateCurrentTime,
      continueWithSetSubs,
      setIsPressExitButton,
      isFirstPlayed,
      setIsFirstPlayed,
    }),
    [
      VideoRefs,
      selectedVideoId,
      setSelectedVideoId,
      showCPanel,
      hideCPanel,
      currentlyAtRef,
      isShakaInstalledOk,
      shakaInstances,
      maxVideoSizeForVariant,
      subtitleRefs,
      castSubtitles,
      displaySubs,
      plstDisplaySubs,
      pressPlayPause,
      plstPlayPause,
      updateClipPosition,
      trackPlaybackStateChange,
      seekTo,
      plstSeekTo,
      getReturnURL,
      isPreview,
      initOneTrack,
      plstPlaybackStateChange,
      updateCurrentTime,
      continueWithSetSubs,
      setIsPressExitButton,
      isFirstPlayed,
      setIsFirstPlayed,
      contentType,
    ],
  )

  return (
    <>
      {learnObjLoading && (isPortableDevice ? <PortableSkeleton /> : <DesktopSkeleton />)}
      {(learnObjReady || learnObjError) && (
        <PreviewContext.Provider value={videoProps}>
          <MainSection>
            {isPortableDevice ? (
              <PortablePanel {...videoProps} />
            ) : (
              <DesktopPanel /* {...videoProps}  */ />
            )}
            {isAbortModal.on && (
              <AbortModal VideoRefs={VideoRefs} shakaInstances={shakaInstances} socket={socket} />
            )}
          </MainSection>
        </PreviewContext.Provider>
      )}
      <SpentTimeTracker
        // socket={socket}
        isPreview={isPreview.current}
        currentlyAtRef={currentlyAtRef}
        pressPlayPause={['Remix', 'RemixV2'].includes(contentType) ? pressPlayPause : plstPlayPause}
        seekTo={['Remix', 'RemixV2'].includes(contentType) ? seekTo : plstSeekTo}
        isPressExitButton={isPressExitButton}
      />
    </>
  )
}

export default AppFrame
